import React from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, FileOutlined, FormOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Link, Outlet } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

const items = [
  { key: '1', icon: React.createElement(FileOutlined), label: <Link to="/manage/applications">Applications</Link> },
  { key: '2', icon: React.createElement(VideoCameraOutlined), label: <Link to="/manage/interviews">Interviews</Link> },
  { key: '3', icon: React.createElement(CheckCircleOutlined), label: <Link to="/manage/employments">Employments</Link> },
  { key: '4', icon: React.createElement(FormOutlined), label: <Link to="/manage/surveys">Surveys</Link> },
];

const Manage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className='flex items-center ml-4 w-full justify-center'>
            <img src="../logo512.png" alt="Company Logo" className="h-12 mr-4" />
            <h1 className='text-lg font-bold font-serif text-blue-900 tracking-widest'>Axis Global Enterprise Employment Center</h1>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              flex: 1,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Copyright &copy; {new Date().getFullYear()} Axis Global Enterprise. All rights reserved.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Manage;
