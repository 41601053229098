import React, { useEffect } from 'react';
import { Row, Col, Typography, Form, Input, Button } from 'antd';

const { Title, Paragraph } = Typography;

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Row gutter={[16, 16]} className='w-full mt-8 px-8'>
        <Col span={24}>
          <Title level={2}>Contact Us</Title>
          <Paragraph>
            If you have any questions or need further information, please contact us using the details below:
          </Paragraph>
          <Paragraph>
            <strong>Email:</strong> axisglobalenterprise01@gmail.com
          </Paragraph>
          <Paragraph>
            <strong>Phone:</strong> +22870821873
          </Paragraph>
          <Paragraph>
            <strong>Address:</strong> Lomé, Togo
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
}

export default Contact;
