import React, { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp, FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const navItems = ['Home', 'Services', 'Jobs', 'FAQ', 'Contact', 'About Us'];

  const getNavItemClass = (item) => {
    const path = `/${item.toLowerCase().replace(/\s/g, '-')}`;
    return location.pathname === path ? 'text-blue-500' : 'text-black';
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderFixed(window.scrollY > 0.0000005);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`bg-white z-10 w-full py-4 ${isHeaderFixed ? 'fixed top-0 shadow-md' : 'shadow-sm'}`}>
      <div className="container mx-auto flex items-center justify-between flex-wrap px-4">
        <div className="flex items-center">
          <img src="logo512.png" alt="Company Logo" className="h-12 mr-4" />
          <h1 className='text-md font-bold font-serif text-blue-900 tracking-widest lg:hidden'>Axis Global Enterprise</h1>
          <h1 className='text-md font-bold font-serif text-blue-900 tracking-widest hidden lg:block'>Axis Global<br />Enterprise</h1>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="lg:hidden ml-4 focus:outline-none">
            {isMenuOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
          </button>
        </div>
        <div className={`w-full lg:w-auto lg:flex lg:items-center lg:justify-center ${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
          <nav className="flex flex-col lg:flex-row gap-3 lg:gap-7 justify-center mt-4 lg:mt-0 lg:mr-14">
            {navItems.map(item => (
              <Link
                key={item}
                to={`/${item.toLowerCase().replace(/\s/g, '-')}`}
                className={`cursor-pointer ${getNavItemClass(item)}`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item}
              </Link>
            ))}
          </nav>
          <div className="flex gap-4 mt-4 lg:mt-0 justify-center">
            <a href="https://www.facebook.com/profile.php?id=61559997526428" target='_blank' className="hover:text-gray-400"><FaFacebook size={25} color="#1877F2" /></a>
            <a href="https://wa.me/message/6PUSLBI64DTWC1" target='_blank' className="hover:text-gray-400"><FaWhatsapp size={25} color="#25D366" /></a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
