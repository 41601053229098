import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-10 mt-auto px-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center md:items-start">
          <h5 className="text-lg font-bold mb-4">About Us</h5>
          <p className="text-sm">
            We provide exceptional services in port operations, technical support, freight forwarding, business consultation, and more. Join our team and be a part of a dynamic and innovative company.
          </p>
        </div>
        <div className="flex flex-col items-center md:items-start">
          <h5 className="text-lg font-bold mb-4">Quick Links</h5>
          <ul className="space-y-2 text-sm">
            <li><Link to="/home" className="hover:underline">Home</Link></li>
            <li><Link to="/services" className="hover:underline">Services</Link></li>
            <li><Link to="/jobs" className="hover:underline">Jobs</Link></li>
            <li><Link to="/faq" className="hover:underline">FAQ</Link></li>
            <li><Link to="/contact" className="hover:underline">Contact</Link></li>
            <li><Link to="/about-us" className="hover:underline">About Us</Link></li>
          </ul>
        </div>
        <div className="flex flex-col items-center md:items-start">
          <h5 className="text-lg font-bold mb-4">Follow Us</h5>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61559997526428" target='_blank' className="hover:text-gray-400"><FaFacebook size={25} color="#1877F2" /></a>
            <a href="https://wa.me/message/6PUSLBI64DTWC1" target='_blank' className="hover:text-gray-400"><FaWhatsapp size={25} color="#25D366" /></a>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-8 border-t border-white opacity-50 pt-4 text-center">
        <p className="text-sm">Copyright &copy; 2024 Axis Global Enterprise. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
