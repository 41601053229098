import React, { useEffect } from 'react';
import { Row, Col, Typography, Carousel, Card } from 'antd';
const { Title, Paragraph } = Typography;

const About = () => {
  const sectionStyle = {
    marginBottom: '40px',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-10'>
      <div style={sectionStyle}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Title level={2}>Our Mission</Title>
            <Paragraph>
              At Axis Global Enterprise, our mission is to revolutionize the logistics and supply chain industry through our integrated services. We are committed to delivering seamless port operations, efficient freight forwarding, and comprehensive logistics management solutions. By leveraging cutting-edge technology and maintaining a relentless focus on efficiency, we ensure the timely and secure transfer of goods, enhancing global trade and connectivity.
            </Paragraph>
            <Paragraph>
              We believe in empowering our clients through exceptional technical support and expert business consultation services. Our team of dedicated professionals provides strategic insights, hands-on support, and innovative solutions to help businesses navigate complex challenges, optimize operations, and achieve sustainable growth. By fostering a culture of continuous learning and professional development, we ensure our team remains at the forefront of industry advancements.
            </Paragraph>
            <Paragraph>
              Axis Global Enterprise is dedicated to maintaining the highest standards of integrity, environmental responsibility, and customer satisfaction. We adopt eco-friendly practices, comply with international maritime regulations, and prioritize safety in all our operations. Our commitment to excellence and accountability ensures that we build lasting relationships with our clients, delivering exceptional service and creating lasting value for all our stakeholders.
            </Paragraph>
          </Col>
          <Col xs={24} sm={12}>
            <img alt="Mission" src="mission.jpg" style={{ width: '100%' }} className='rounded-[25px]' />
          </Col>
        </Row>
      </div>

      <div style={sectionStyle}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <img alt="Vision" src="vision.jpg" style={{ width: '100%' }} className='rounded-[25px]' />
          </Col>
          <Col xs={24} sm={12}>
            <Title level={2}>Our Vision</Title>
            <Paragraph>
              At Axis Global Enterprise, our vision is to become the global leader in logistics and supply chain solutions, recognized for our innovation, sustainability, and unwavering commitment to excellence. We aspire to create a seamless and interconnected global trade network, where efficiency and reliability drive economic growth and development.
            </Paragraph>
            <Paragraph>
              We envision a future where our advanced technologies and integrated services set new industry standards, empowering businesses to thrive in an ever-evolving marketplace. By fostering a culture of continuous improvement and embracing sustainable practices, we aim to minimize environmental impact and contribute to a more sustainable world.
            </Paragraph>
            <Paragraph>
              Our goal is to build lasting partnerships with our clients, employees, and communities, driving success through collaboration, integrity, and transparency. Through our dedication to quality and service, we strive to make Axis Global Enterprise the trusted name in logistics and supply chain management, delivering unparalleled value to our stakeholders worldwide.
            </Paragraph>
          </Col>
        </Row>
      </div>

      <div style={sectionStyle}>
        <Row gutter={[16, 16]} className='flex justify-center text-center'>
          <Col>
            <Paragraph>
              <Title level={2}>Our Values</Title>
              <Values />
            </Paragraph>
          </Col>
        </Row>
      </div>

      <div style={sectionStyle}>
        <Row gutter={[16, 16]} className='flex justify-center text-center'>
          <Col xs={24} sm={12}>
            <Title level={2}>Our History</Title>
            <Paragraph>
              Axis Global Enterprise is an international company founded by visionary professionals who recognized the growing need for efficient technical support, software development, and business consultation services in an increasingly digital world. With a strong commitment to innovation, the company set out to revolutionize these industries by integrating cutting-edge technology with traditional business practices. This dedication enabled Axis Global Enterprise to rapidly establish a robust presence in the global market.
            </Paragraph>
            <Paragraph>
              As the company grew, it strategically diversified its services to provide comprehensive technical support and business consultation. This expansion aimed to offer end-to-end solutions for clients, encompassing everything from IT support to strategic business planning. By continuously enhancing its service offerings and maintaining a customer-centric approach, Axis Global Enterprise solidified its position as a leader in technical support, software development, and business consultation.
            </Paragraph>
            <Paragraph>
              Axis Global Enterprise remains committed to sustainability and innovation, adopting eco-friendly practices and integrating IoT and advanced management systems to reduce its environmental impact. Today, the company stands as a testament to the power of vision, dedication, and continuous improvement, serving clients worldwide and contributing to the global economy through efficient, reliable, and sustainable business solutions. By investing in state-of-the-art software development and providing robust technical support, Axis Global Enterprise empowers businesses to optimize their operations, enhance productivity, and achieve their strategic goals in a rapidly evolving technological landscape.
            </Paragraph>

          </Col>
        </Row>
      </div>
    </div>
  );
}

const Values = () => {
  const valuesData = [
    {
      title: "Integrity",
      description: "We uphold the highest standards of integrity in all of our actions.",
      key: 1
    },
    {
      title: "Innovation",
      description: "We constantly innovate to meet the changing needs of our customers and industry.",
      key: 2
    },
    {
      title: "Collaboration",
      description: "We believe in collaborative partnerships both internally and externally to achieve success.",
      key: 3
    },
    {
      title: "Excellence",
      description: "We strive for excellence in everything we do, aiming to exceed expectations.",
      key: 4
    }
  ];

  const cardStyle = {
    padding: '4px',
    margin: 'auto',
    marginTop: '20px',
    backgroundColor: '#0D47A1',
    border: 'none',
    color: 'white',
    fontSize: '14px',
    borderRadius: '20px'
  };

  const titleStyle = {
    color: 'white',
    borderBottom: 'none',
    fontSize: '24px',
    borderRadius: '20px 20px 0 0',
  };

  return (
    <Carousel autoplay dots arrows>
      {valuesData.map(value => (
        <div key={value.key}>
          <Card title={value.title} style={cardStyle} headStyle={titleStyle} className='py-4'>
            <p>{value.description}</p>
          </Card>
        </div>
      ))}
    </Carousel>
  );
}

export default About;
