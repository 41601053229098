import React, { useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
const { Title, Paragraph } = Typography;

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='px-4 lg:px-10 py-4'>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Title level={2}>Our Services</Title>
                </Col>
            </Row>


            <Row gutter={[16, 16]} className='mb-6'>
                <Col xs={24}>
                    <Card>
                        <Row gutter={[16, 16]} className='flex items-center'>
                            <Col xs={24} sm={12}>
                                <TechnicalSupport />
                            </Col>
                            <Col xs={24} sm={12}>
                                <img alt="Technical Support" src="technical.jpeg" style={{ width: '100%' }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className='mb-6'>
                <Col xs={24}>
                    <Card>
                        <Row gutter={[16, 16]} className='flex items-center'>
                            <Col xs={24} sm={12}>
                                <img alt="Software Development" src="software.jpg" style={{ width: '100%' }} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <SoftwareDevelopmentService />
                            </Col>

                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className='mb-6'>
                <Col xs={24}>
                    <Card>
                        <Row gutter={[16, 16]} className='flex items-center'>
                            <Col xs={24} sm={12}>
                                <img alt="Freight Forwarding" src="freight.png" style={{ width: '100%' }} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <FreightForwarding />
                            </Col>

                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className='mb-6'>
                <Col xs={24}>
                    <Card>
                        <Row gutter={[16, 16]} className='flex items-center'>
                            <Col xs={24} sm={12}>
                                <BusinessConsultation />
                            </Col>
                            <Col xs={24} sm={12}>
                                <img alt="Business Consultation" src="business-consultants.jpg" style={{ width: '100%' }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className='mb-6'>
                <Col xs={24}>
                    <Card>
                        <Row gutter={[16, 16]} className='flex items-center'>
                            <Col xs={24} sm={12}>
                                <img alt="Port Operation" src="port1.jpg" style={{ width: '100%' }} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <PortOperation />
                            </Col>

                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

const PortOperation = () => (
    <div style={{ padding: '20px' }}>
        <Title level={4}>Port Operation</Title>
        <Paragraph>
            Our port operation services ensure the seamless and efficient management of all port activities. We handle cargo with precision, utilizing advanced equipment to expedite loading and unloading processes. This minimizes delays and ensures the safe transfer of goods, maintaining the integrity of the cargo.
        </Paragraph>
        <Paragraph>
            Vessel berthing is meticulously coordinated, optimizing schedules to reduce waiting times and enhance port throughput. Our professional mooring services guarantee secure anchorage, safeguarding vessels during their stay at the port.
        </Paragraph>
        <Paragraph>
            In addition, our logistics management services cover customs clearance, transportation, and distribution, ensuring compliance with regulations and timely delivery of goods. By integrating cutting-edge technology, including port management systems and IoT, we enhance operational transparency and efficiency.
        </Paragraph>
        <Paragraph>
            Safety and environmental sustainability are paramount. We adhere to international maritime regulations and adopt eco-friendly practices to minimize our environmental impact. Through this comprehensive approach, we ensure a smooth flow of goods and materials, supporting the global supply chain effectively.
        </Paragraph>
    </div>
);

const TechnicalSupport = () => (
    <div style={{ padding: '20px' }}>
        <Title level={4}>Technical Support</Title>
        <Paragraph>
            Our technical support services provide a comprehensive range of solutions to ensure the efficient resolution of technical issues and optimal system functionality. We offer 24/7 support, addressing all technical inquiries promptly to minimize downtime and maintain productivity.
        </Paragraph>
        <Paragraph>
            Our team of experts specializes in troubleshooting hardware and software problems, offering remote and on-site assistance to meet your needs. We employ advanced diagnostic tools to identify and resolve issues quickly, ensuring your systems run smoothly.
        </Paragraph>
        <Paragraph>
            In addition to problem resolution, we offer proactive maintenance services, including system updates and performance optimization. This helps prevent potential issues and ensures your technology infrastructure remains robust and reliable.
        </Paragraph>
        <Paragraph>
            We also provide training and guidance to help your team effectively utilize technology, enhancing their skills and productivity. Our commitment to excellence ensures that your technical challenges are handled efficiently, allowing you to focus on your core business operations.
        </Paragraph>
    </div>
);

const FreightForwarding = () => (
    <div style={{ padding: '20px' }}>
        <Title level={4}>Freight Forwarding</Title>
        <Paragraph>
            Our freight forwarding services streamline the cargo shipment process, ensuring efficiency and reliability from start to finish. We offer cost-effective solutions tailored to meet your specific needs, helping you save on shipping costs while maintaining high service standards.
        </Paragraph>
        <Paragraph>
            Timely delivery is at the core of our operations. We coordinate closely with carriers and leverage a global network of partners to ensure your cargo reaches its destination on schedule. Our team monitors shipments in real-time, providing updates and addressing any issues promptly to avoid delays.
        </Paragraph>
        <Paragraph>
            Optimized logistics management is a key aspect of our service. We handle all aspects of the shipping process, including documentation, customs clearance, and cargo insurance, simplifying the complexities of international trade for you. Our expertise in logistics ensures that your goods are transported efficiently and securely.
        </Paragraph>
        <Paragraph>
            In addition, we offer value-added services such as warehousing, consolidation, and distribution, providing a comprehensive solution for all your shipping needs. Our commitment to excellence ensures a seamless and hassle-free experience, allowing you to focus on your business growth.
        </Paragraph>
    </div>
);

const BusinessConsultation = () => (
    <div style={{ padding: '20px' }}>
        <Title level={4}>Business Consultation</Title>
        <Paragraph>
            Our business consultation services provide expert guidance and support across a wide range of industries. We offer insights and solutions tailored to meet the unique needs of each client, helping you navigate complex business challenges with confidence.
        </Paragraph>
        <Paragraph>
            We begin by conducting a thorough analysis of your business operations, identifying areas for improvement and growth opportunities. Our team of experienced consultants works closely with you to develop strategic plans that align with your goals and industry requirements.
        </Paragraph>
        <Paragraph>
            In addition to strategic planning, we offer specialized consulting in areas such as financial management, market analysis, and operational efficiency. Our recommendations are grounded in industry best practices and backed by data-driven insights, ensuring that you make informed decisions.
        </Paragraph>
        <Paragraph>
            Our support extends beyond advisory services. We assist with the implementation of our recommendations, providing hands-on support to ensure successful execution. Whether you need help with organizational restructuring, process optimization, or technology integration, we are here to guide you every step of the way.
        </Paragraph>
        <Paragraph>
            By leveraging our expertise, you can enhance your business performance, adapt to changing market conditions, and achieve sustainable growth. Our commitment to excellence ensures that you receive the highest level of service and value.
        </Paragraph>
    </div>
);

const SoftwareDevelopmentService = () => (
    <div style={{ padding: '20px' }}>
        <Title level={4}>Software Development</Title>
        <Paragraph>
            Our software development services encompass a wide array of expertise to cater to your project needs. We specialize in delivering high-quality, custom software solutions tailored to your specific requirements, ensuring optimal performance and scalability.
        </Paragraph>
        <Paragraph>
            From ideation to deployment, our experienced team collaborates closely with you to understand your vision and translate it into a robust software product. We leverage cutting-edge technologies and industry best practices to develop solutions that drive innovation and exceed expectations.
        </Paragraph>
        <Paragraph>
            Our services cover various aspects of the software development lifecycle, including requirements gathering, architecture design, coding, testing, and deployment. Whether you need a web application, mobile app, desktop software, or enterprise solution, we have the expertise to bring your ideas to life.
        </Paragraph>
        <Paragraph>
            We prioritize communication and transparency throughout the development process, keeping you informed and involved at every stage. Our agile approach enables us to adapt to changing requirements and deliver results efficiently, ensuring on-time and within-budget delivery.
        </Paragraph>
        <Paragraph>
            Partnering with us means more than just building software – it means gaining a strategic technology partner dedicated to your success. With our proven track record of delivering impactful solutions, you can trust us to transform your vision into reality and drive your business forward.
        </Paragraph>
    </div>
);


export default Services;
