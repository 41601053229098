import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Landing from './components/Landing';
import Services from './pages/Services';
import Jobs from './pages/Jobs';
import Faq from './pages/Faq';
import JobApplicationPage from './pages/Application';
import Manage from './pages/Manage';
import Applications from './pages/manage/Applications';
import Interviews from './pages/manage/Interviews';
import Employments from './pages/manage/Employments';
import Surveys from './pages/manage/Surveys';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
        </Route>
        <Route path="/apply/:role" element={<JobApplicationPage />} />
        <Route path="/manage" element={<Manage />} >
          <Route path="/manage/" element={<Applications />} />
          <Route path="/manage/applications" element={<Applications />} />
          <Route path="/manage/interviews" element={<Interviews />} />
          <Route path="/manage/employments" element={<Employments />} />
          <Route path="/manage/surveys" element={<Surveys />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;
