import React, { useEffect, useState } from 'react';
import { Button, List, Collapse, Modal, Form, TimePicker, DatePicker, Input } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';
import activeBaseUrl from '../../utils/urls';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();



const { Panel } = Collapse;



const Applications = () => {
    const [cvModalVisible, setCvModalVisible] = useState(false);
    const [selectedCv, setSelectedCv] = useState(null);
    const [passportModalVisible, setPassportModalVisible] = useState(false);
    const [selectedPassport, setSelectedPassport] = useState(null);

    const [ivModalVisible, setIvModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };


    const fetchApplications = async () => {
        try {
            const response = await axios.get(`${activeBaseUrl}/applications`);
            setApplications(response.data.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApplications();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const invitedApplicants = applications.filter(applicant => applicant.invitedForInterview);

    const handleInvite = async (id) => {
        setSelectedId(id);
        setIvModalVisible(true);
    };

    const handleCloseIvModal = () => setIvModalVisible(false);


    const handleSubmit = async (id, formData) => {
        try {
            await axios.post(`${activeBaseUrl}/applications/send-invite/${id}`, formData);

            const updatedData = applications.map(applicant => {
                if (applicant._id === id) {
                    return { ...applicant, invitedForInterview: true };
                }
                return applicant;
            });

            setApplications(updatedData);
            handleCloseIvModal(false);
            console.log('Invite sent successfully');
        } catch (error) {
            console.error('Error sending invite:', error);
        }
    };

    const handleViewCV = (cv) => {
        setSelectedCv(cv);
        setCvModalVisible(true);
    };

    const handleCloseCVModal = () => {
        setSelectedCv(null);
        setCvModalVisible(false);
    };

    const handleViewPassport = (passport) => {
        setSelectedPassport(passport);
        setPassportModalVisible(true);
    };

    const handleClosePassportModal = () => {
        setSelectedPassport(null);
        setPassportModalVisible(false);
    };

    const InviteModal = ({ visible, onClose, onSubmit, id }) => {
        const [form] = Form.useForm();

        const handleOk = async () => {
            try {
                const values = await form.validateFields();
                await onSubmit(id, {
                    ...values,
                    date: values.date.format('YYYY-MM-DD'),
                    time: values.time.format('HH:mm')
                });
                form.resetFields();
                onClose();
            } catch (error) {
                console.error('Validation Failed:', error);
            }
        };

        return (
            <Modal
                title="Schedule Interview"
                visible={visible}
                onOk={handleOk}
                onCancel={onClose}
                okText="Send Invite"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="time"
                        label="Time"
                        rules={[{ required: true, message: 'Please select the time!' }]}
                    >
                        <TimePicker format="HH:mm" />
                    </Form.Item>
                    <Form.Item
                        name="link"
                        label="Meeting Link"
                        rules={[{ required: true, message: 'Please enter the meeting link!' }]}
                    >
                        <Input type="url" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const renderItem = (item, handleInvite) => (
        <List.Item key={item._id}>
            <Collapse defaultActiveKey={['0']}>
                <Panel
                    header={
                        <div className="flex justify-between items-center">
                            <span className="font-bold">{`${item.firstname} ${item.surname}`}</span>
                            <div className="flex items-center">
                                <span className="text-blue-800 mr-4">{moment(item.createdAt).format('YYYY-MM-DD')}</span>
                                <span className="bg-blue-200 text-blue-800 px-4 py-1 rounded-lg">{item.role}</span>
                            </div>
                        </div>
                    }
                    key="1"
                >
                    {/* Display all fields within the collapsed card */}
                    <p>First Name: {item.firstname}</p>
                    <p>Middle Name: {item.middleName}</p>
                    <p>Surname: {item.surname}</p>
                    <p>Marital Status: {item.maritalStatus}</p>
                    <p>Gender: {item.gender}</p>
                    <p>Phone Number: {item.phoneNumber}</p>
                    <p>Email: {item.email}</p>
                    <p>ID Type: {item.idType}</p>
                    <p>ID Number: {item.idNumber}</p>
                    <p>Nationality: {item.nationality.label}</p>
                    <p>Date of Birth: {moment(item.dateOfBirth).format('YYYY-MM-DD')}</p>
                    <p>Next of Kin: {item.nextOfKin}</p>
                    <p>Relationship: {item.relationship}</p>
                    <p>Next of Kin Phone Number: {item.nextOfKinPhoneNumber}</p>
                    <p>Mother's Maiden Name: {item.motherMaidenName}</p>
                    <p>Role: {item.role}</p>
                    {/* CV and Passport Photograph fields */}
                    <p>CV: {item.resume ? <Button onClick={() => handleViewCV(item.resume)}>View CV</Button> : "Not Available"}</p>
                    <p>Passport Photograph: {item.passport ? <Button onClick={() => handleViewPassport(item.passport)}>View Passport</Button> : "Not Available"}</p>
                    {item.invitedForInterview ? (
                        <p className='text-green-500 font-bold my-4'>Already Invited for Interview</p>
                    ) : (
                        <Button type="primary" onClick={() => handleInvite(item._id)} className='mt-4'>
                            Invite for Interview
                        </Button>
                    )}
                </Panel>
            </Collapse>
        </List.Item>
    );

    return (
        <div>
            <div className='flex justify-between'>
                <h1 className="text-2xl font-bold mb-1">Applications</h1>
                <h2 className="text-xl font-bold mb-1 text-blue-800 mr-4">Total: {applications.length}</h2>
            </div>
            <h2 className="text-lg font-semibold mb-2">Waiting: {applications.length - invitedApplicants.length}</h2>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={applications.filter(applicant => !applicant.invitedForInterview)}
                renderItem={(item) => renderItem(item, handleInvite)}
            />
            <h2 className="text-lg font-semibold mb-2">Invitees: {invitedApplicants.length}</h2>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={invitedApplicants}
                renderItem={(item) => renderItem(item, handleInvite)}
            />
            <Modal
                title="CV Preview"
                visible={cvModalVisible}
                onCancel={handleCloseCVModal}
                footer={[
                    <Button key="close" onClick={handleCloseCVModal}>
                        Close
                    </Button>
                ]}
                style={{ top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto' }} // Set modal styles
                bodyStyle={{ maxHeight: 'calc(100vh - 108px)', overflowY: 'auto' }} // Set body styles
            >
                <Document
                    file={selectedCv}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                <div className="flex justify-between items-center py-4 px-6 bg-gray-100">
                    <p className="text-gray-700">
                        Page {pageNumber} of {numPages}
                    </p>
                    <div className="space-x-2">
                        <button onClick={previousPage} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                            Previous
                        </button>
                        <button onClick={nextPage} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                            Next
                        </button>
                    </div>
                </div>

            </Modal>
            <Modal
                title="Passport Photograph Preview"
                visible={passportModalVisible}
                onCancel={handleClosePassportModal}
                footer={[
                    <Button key="close" onClick={handleClosePassportModal}>
                        Close
                    </Button>
                ]}
            >
                {selectedPassport && <img src={selectedPassport} alt="Passport Photograph" style={{ maxWidth: '100%', maxHeight: '80vh' }} />}
            </Modal>
            <InviteModal
                visible={ivModalVisible}
                onClose={handleCloseIvModal}
                onSubmit={handleSubmit}
                id={selectedId}
            />
        </div>
    );
};

export default Applications;
