import React, { useEffect, useState } from 'react';
import { Button, List, Collapse, Modal, DatePicker, Form } from 'antd';
import axios from 'axios';
import moment from 'moment';
import activeBaseUrl from '../../utils/urls';

const { Panel } = Collapse;

const Interviews = () => {
    const [cvModalVisible, setCvModalVisible] = useState(false);
    const [selectedCv, setSelectedCv] = useState(null);
    const [passportModalVisible, setPassportModalVisible] = useState(false);
    const [selectedPassport, setSelectedPassport] = useState(null);

    const [employmentModalVisible, setEmploymentModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchApplications = async () => {
        try {
            const response = await axios.get(`${activeBaseUrl}/applications`);
            setApplications(response.data.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApplications();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const invitedApplicants = applications.filter(applicant => applicant.invitedForInterview);
    const completedInterviewApplicants = invitedApplicants.filter(applicant => applicant.completedInterview);

    const handleCompleteInterview = async (id) => {
        try {
            // Make an API call to send the invite
            const response = await axios.post(`${activeBaseUrl}/applications/complete-interview/${id}`);

            // If the API call is successful, update the state locally
            const updatedData = applications.map(applicant => {
                if (applicant._id === id) {
                    return { ...applicant, completedInterview: true };
                }
                return applicant;
            });

            setApplications(updatedData);

            // Optionally, you can show a success message or perform other actions
            console.log('Interview completed successfully');
        } catch (error) {
            // Handle errors, such as network issues or server errors
            console.error('Error completing interview:', error);
        }
    };

    const handleCloseEmploymentModal = () => setEmploymentModalVisible(false);

    const handleOfferEmployment = async (id) => {
        setSelectedId(id);
        setEmploymentModalVisible(true);
    };

    const handleSubmit = async (id, formData) => {
        try {
            const response = await axios.post(`${activeBaseUrl}/applications/offer-employment/${id}`, formData);
            console.log('Response:', response.data);

            const updatedData = applications.map(applicant => {
                if (applicant._id === id) {
                    return { ...applicant, offeredEmployment: true };
                }
                return applicant;
            });

            setApplications(updatedData);

            setEmploymentModalVisible(false);

            console.log('Employment offered successfully');
        } catch (error) {
            console.error('Error offerring employment:', error);
        }
    };

    const handleViewCV = (cv) => {
        setSelectedCv(cv);
        setCvModalVisible(true);
    };

    const handleCloseCVModal = () => {
        setSelectedCv(null);
        setCvModalVisible(false);
    };

    const handleViewPassport = (passport) => {
        setSelectedPassport(passport);
        setPassportModalVisible(true);
    };

    const handleClosePassportModal = () => {
        setSelectedPassport(null);
        setPassportModalVisible(false);
    };

    const InviteModal = ({ visible, onClose, onSubmit, id }) => {
        const [form] = Form.useForm();

        const handleOk = async () => {
            try {
                const values = await form.validateFields();
                await onSubmit(id, {
                    date: values.date.format('YYYY-MM-DD')
                });
                form.resetFields();
                onClose();
            } catch (error) {
                console.error('Validation Failed:', error);
            }
        };

        return (
            <Modal
                title="Arrival Date"
                visible={visible}
                onOk={handleOk}
                onCancel={onClose}
                okText="Send Offer"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const renderItem = (item, handleCompleteInterview) => (
        <List.Item key={item._id}>
            <Collapse defaultActiveKey={['0']}>
                <Panel
                    header={
                        <div className="flex justify-between items-center">
                            <span className="font-bold">{`${item.firstname} ${item.surname}`}</span>
                            <div className="flex items-center">
                                <span className="text-blue-800 mr-4">{moment(item.createdAt).format('YYYY-MM-DD')}</span>
                                <span className="bg-blue-200 text-blue-800 px-4 py-1 rounded-lg">{item.role}</span>
                            </div>
                        </div>
                    }
                    key="1"
                >
                    {/* Display all fields within the collapsed card */}
                    <p>First Name: {item.firstname}</p>
                    <p>Middle Name: {item.middleName}</p>
                    <p>Surname: {item.surname}</p>
                    <p>Marital Status: {item.maritalStatus}</p>
                    <p>Gender: {item.gender}</p>
                    <p>Phone Number: {item.phoneNumber}</p>
                    <p>Email: {item.email}</p>
                    <p>ID Type: {item._idType}</p>
                    <p>ID Number: {item._idNumber}</p>
                    <p>Nationality: {item.nationality.label}</p>
                    <p>Date of Birth: {moment(item.dateOfBirth).format('YYYY-MM-DD')}</p>
                    <p>Next of Kin: {item.nextOfKin}</p>
                    <p>Relationship: {item.relationship}</p>
                    <p>Next of Kin Phone Number: {item.nextOfKinPhoneNumber}</p>
                    <p>Mother's Maiden Name: {item.motherMaidenName}</p>
                    <p>Role: {item.role}</p>
                    {/* CV and Passport Photograph fields */}
                    <p>CV: {item.resume ? <Button onClick={() => handleViewCV(item.resume)}>View CV</Button> : "Not Available"}</p>
                    <p>Passport Photograph: {item.passport ? <Button onClick={() => handleViewPassport(item.passport)}>View Passport</Button> : "Not Available"}</p>
                    {item.completedInterview ? (
                        <div>
                            <p className='text-green-500 font-bold mt-4'>Interview Completed</p>
                            {item.offeredEmployment ? (<p className='text-green-500 font-bold mt-4'>Employment Offered</p>) : (<Button type="primary" onClick={() => handleOfferEmployment(item._id)} className='mt-4'>
                                Offer Employment
                            </Button>)}
                        </div>
                    ) : (
                        <Button type="primary" onClick={() => handleCompleteInterview(item._id)} className='mt-4'>
                            Complete Interview
                        </Button>
                    )}
                </Panel>
            </Collapse>
        </List.Item>
    )

    return (
        <div>
            <div className='flex justify-between'>
                <h1 className="text-2xl font-bold mb-1">Interviews</h1>
                <h2 className="text-xl font-bold mb-1 text-blue-800 mr-4">Total: {invitedApplicants.length}</h2>
            </div>
            <h2 className="text-lg font-semibold mb-2">Invitees: {invitedApplicants.length - completedInterviewApplicants.length}</h2>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={invitedApplicants.filter(applicant => !applicant.completedInterview)}
                renderItem={(item) => renderItem(item, handleCompleteInterview)}
            />
            <h2 className="text-lg font-semibold mb-2">Completed: {completedInterviewApplicants.length}</h2>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={completedInterviewApplicants}
                renderItem={(item) => renderItem(item, handleCompleteInterview)}
            />
            <Modal
                title="CV Preview"
                visible={cvModalVisible}
                onCancel={handleCloseCVModal}
                footer={[
                    <Button key="close" onClick={handleCloseCVModal}>
                        Close
                    </Button>
                ]}
            >
                {selectedCv && <iframe src={selectedCv} style={{ width: '100%', height: '500px' }} />}
            </Modal>
            <Modal
                title="Passport Photograph Preview"
                visible={passportModalVisible}
                onCancel={handleClosePassportModal}
                footer={[
                    <Button key="close" onClick={handleClosePassportModal}>
                        Close
                    </Button>
                ]}
            >
                {selectedPassport && <img src={selectedPassport} alt="Passport Photograph" style={{ maxWidth: '100%', maxHeight: '80vh' }} />}
            </Modal>
            <InviteModal
                visible={employmentModalVisible}
                onClose={handleCloseEmploymentModal}
                onSubmit={handleSubmit}
                id={selectedId}
            />
        </div>
    );
};

export default Interviews;
