import React, { useEffect } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { slugify } from '../utils/slugs';
import activeBaseUrl from '../utils/urls';
import axios from 'axios';

const Jobs = () => {
    const jobOpenings = [
        {
            "title": "Accountant",
            "location": "Lomé, Togo",
            "description": "Manage financial records, prepare financial statements, and ensure compliance with regulations.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in Accounting, Economics, Finance, or related field required. CPA or similar certification preferred. Strong attention to detail, analytical skills, and knowledge of accounting principles is essential.",
            "benefits": "Competitive salary, health insurance, and opportunities for professional development."
        },
        {
            "title": "Technical Support Engineer",
            "location": "Lomé, Togo",
            "description": "Provide technical support to clients, troubleshoot issues, and ensure system stability.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in any Engineering discipline, computer science, information technology, or related field preferred. Previous experience in technical support or IT helpdesk roles is advantageous. Strong problem-solving, communication, and customer service skills required. Knowledge of relevant software and systems is beneficial.",
            "benefits": "Competitive salary, opportunities for skill development and career advancement, health insurance, and a supportive work environment."
        },
        {
            "title": "Business Consultant",
            "location": "Lomé, Togo",
            "description": "Advise clients on business strategies, market analysis, and operational improvements.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in business administration, management, finance, or related field preferred. Previous experience in consulting, business analysis, or relevant industries is advantageous. Strong analytical, problem-solving, and communication skills required. Knowledge of market trends, strategic planning, and industry best practices is beneficial.",
            "benefits": "Competitive salary, opportunities for professional development, health insurance, and a stimulating work environment."
        },
        {
            "title": "Security Personnel",
            "location": "Lomé, Togo",
            "description": "Ensure security measures are maintained, monitor premises, and respond to security breaches.",
            "qualifications": "Minimum of SSCE Certificate is required. Previous experience in security or law enforcement preferred. Strong observation, communication, and conflict resolution skills necessary. Certification in security training programs is advantageous.",
            "benefits": "Competitive salary, health insurance, and opportunities for career advancement."
        },

        {
            "title": "French Tutor",
            "location": "Lomé, Togo",
            "description": "Provide French language instruction to students, customize lessons, and assess proficiency.",
            "qualifications": "Native or near-native proficiency in French required. Previous experience in teaching or tutoring preferred. Strong communication, interpersonal, and instructional skills necessary. Certification in teaching French as a second language is advantageous.",
            "benefits": "Competitive salary, opportunities for professional development, and a rewarding work environment."
        },
        {
            "title": "Customer Service Consultant",
            "location": "Lomé, Togo",
            "description": "Assist customers with inquiries, resolve issues, and provide exceptional service.",
            "qualifications": "Minimum of Ordinary National diploma or equivalent in Mass Communication, Public Administration or any field of study is required. Previous experience in customer service or related roles preferred. Strong communication, problem-solving, and interpersonal skills necessary. Familiarity with customer service software and procedures is beneficial.",
            "benefits": "Competitive salary, health insurance, and opportunities for career growth."
        },
        {
            "title": "Sales Representative",
            "location": "Lomé, Togo",
            "description": "Promote products or services, generate leads, and close sales deals.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in Business, Marketing, Entrepreneurship, Mass Communication or related field preferred. Previous experience in sales or customer service roles advantageous. Strong communication, negotiation, and persuasion skills necessary. Ability to work independently and meet sales targets essential.",
            "benefits": "Competitive salary, commission or bonus incentives, health insurance, and opportunities for career advancement."
        },
        {
            "title": "Project Planner",
            "location": "Lomé, Togo",
            "description": "Develop project plans, allocate resources, and monitor progress to ensure timely completion.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in project management, engineering, or related field preferred. Previous experience in project planning or management roles advantageous. Strong organizational, analytical, and communication skills necessary. Proficiency in project management software is beneficial.",
            "benefits": "Competitive salary, health insurance, and opportunities for professional development."
        },
        {
            "title": "Technical Consultant",
            "location": "Lomé, Togo",
            "description": "Provide technical expertise, advise clients on technology solutions, and troubleshoot issues.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in any Engineering discipline, computer science, information technology, or related field required. Previous experience in technical support, IT consulting, or related roles preferred. Strong problem-solving, communication, and interpersonal skills necessary. Proficiency in relevant software and technologies essential.",
            "benefits": "Competitive salary, opportunities for professional development, health insurance, and a supportive work environment."
        },
        {
            "title": "Delivery Personnel",
            "location": "Lomé, Togo",
            "description": "Deliver packages or goods to customers, ensure timely delivery, and maintain vehicle safety.",
            "qualifications": "Minimum of SSCE Certificate is required. Previous experience in delivery or courier services is advantageous. Physical fitness is essential.",
            "benefits": "Competitive salary, health insurance, and opportunities for overtime pay."
        },
        {
            "title": "Logistics Personnel",
            "location": "Lomé, Togo",
            "description": "Manage logistics operations, optimize transportation routes, and oversee inventory management.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in logistics, supply chain management, or related field required. Proficiency in logistics software and knowledge of industry regulations is an added advantage.",
            "benefits": "Competitive salary, health insurance, and opportunities for career advancement."
        },
        {
            "title": "Port Operations Personnel",
            "location": "Lomé, Togo",
            "description": "Manage port operations, oversee logistics, and ensure efficient cargo handling.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in logistics, maritime studies, or a related field preferred. Previous experience in port management or maritime operations is preferred. Knowledge of international shipping regulations and proficiency in relevant software systems is advantageous.",
            "benefits": "Competitive salary, opportunities for professional growth and development, health insurance, and a dynamic work environment in the vibrant port industry."
        },
        {
            "title": "Software Developer",
            "location": "Lomé, Togo",
            "description": "Develop and maintain software applications, collaborate with team members, and participate in the full software development lifecycle.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in computer science, software engineering, or related field required. Proficiency in programming languages such as Java, Python, or C++. Experience with software development methodologies and tools essential. Strong problem-solving and communication skills necessary.",
            "benefits": "Competitive salary, health insurance, and opportunities for professional growth."
        },
        {
            "title": "Freight Forwarder",
            "location": "Lomé, Togo",
            "description": "Coordinate freight shipments, manage documentation, and liaise with carriers.",
            "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in supply chain management, logistics, business administration, or related field preferred. Previous experience in freight forwarding, logistics coordination, or related roles is advantageous. Strong organizational, communication, and problem-solving skills required. Knowledge of international trade regulations and proficiency in relevant software systems is beneficial.",
            "benefits": "Competitive salary, opportunities for professional growth, health insurance, and a collaborative work environment."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        const wakeServer = async () => {
            try {
                const response = await axios.get(`${activeBaseUrl}/health`);
                if (response.status === 200) {
                    console.log('Server is awake!');
                } else {
                    console.error('Failed to wake the server');
                }
            } catch (error) {
                console.error('Error waking up the server:', error);
            }
        };

        wakeServer();
    }, []);

    return (
        <div className="container mx-auto py-5 px-4">
            <h2 className="text-2xl font-bold text-gray-900">Current Openings</h2>
            <p className="mt-2 text-lg text-gray-600">Embrace endless opportunities! Apply for a role that matches your ambitions. Start your journey towards making a global impact and achieving financial independence. Our doors are wide open to applicants from all around the world.</p>

            <div className="mt-10 grid gap-10 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                {jobOpenings.map((job, index) => (
                    <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold text-gray-900">{job.title}</h3>
                        <p className="mt-2 text-sm text-gray-500">{job.location}</p>
                        <p className="mt-4 text-base text-gray-600">{job.description}</p>
                        {job.qualifications && (
                            <div className="mt-4">Requirements
                                <p className="mt-2 text-sm text-gray-600">{job.qualifications}</p>
                            </div>
                        )}
                        {job.benefits && (
                            <div className="mt-4">
                                <h4 className="text-sm font-semibold text-gray-700">Benefits</h4>
                                <p className="mt-2 text-sm text-gray-600">{job.benefits}</p>
                            </div>
                        )}
                        <Link to={`/apply/${slugify(job.title)}`}>
                            <button
                                className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Apply
                                <MdArrowForward className="ml-2" />
                            </button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Jobs;
