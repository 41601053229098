import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { deslugify } from '../utils/slugs';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import axios from 'axios';

import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import activeBaseUrl from '../utils/urls';

function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={22} thickness={6} />
        </React.Fragment>
    );
}


const JobApplicationPage = () => {
    const { role } = useParams();
    const jobTitle = deslugify(role);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const wakeServer = async () => {
            try {
                const response = await axios.get(`${activeBaseUrl}/health`);
                if (response.status === 200) {
                    console.log('Server is awake!');
                } else {
                    console.error('Failed to wake the server');
                }
            } catch (error) {
                console.error('Error waking up the server:', error);
            }
        };

        wakeServer();
    }, []);

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        surname: '',
        maritalStatus: '',
        gender: '',
        phoneNumber: '',
        email: '',
        idType: '',
        idNumber: '',
        nationality: null,
        dateOfBirth: '',
        nextOfKin: '',
        relationship: '',
        nextOfKinPhoneNumber: '',
        motherMaidenName: '',
        role: jobTitle,
        resume: null,
        passport: null
    });

    const [showPopup, setShowPopup] = useState(false);

    const options = countryList().getData();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files[0]
        }));
    };

    const handleNationalityChange = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            nationality: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        // Prepare form data for submission using FormData
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        // Append file inputs to the FormData object if they exist
        if (formData.resume) {
            data.append('resume', formData.resume);
        }
        if (formData.passport) {
            data.append('passport', formData.passport);
        }

        // Log form data to console (for debugging)
        console.log('Form submitted:', formData);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const webUrl = `${activeBaseUrl}/applications/upload`

        axios.post(webUrl, formData, config).then((response) => {
            setLoading(false);
            if (response.data.status === true) {
                setShowPopup(true);
            } else {
                setLoading(false);
                console.error('Submission failed:', response.data);
            }
        });

    };

    const handleClosePopup = () => {
        setShowPopup(false);
        navigate("/home");
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg m-10">
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={handleBackClick}
                    className="text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                    <FaArrowLeft size={18} />
                </button>
                <div className='flex'>
                    <img src="../logo512.png" alt="Company Logo" className="h-12 mr-4" />
                    <h1 className="text-md font-bold font-serif text-blue-900 tracking-widest">Axis Global<br />Enterprise</h1>
                </div>
                <div></div>
            </div>
            <h1 className="mb-6 text-xl font-normal">Apply for {jobTitle}</h1>

            <form className="grid grid-cols-1 lg:grid-cols-2 gap-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Middle Name</label>
                    <input
                        type="text"
                        name="middleName"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.middleName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Surname</label>
                    <input
                        type="text"
                        name="surname"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.surname}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Marital Status</label>
                    <select
                        name="maritalStatus"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.maritalStatus}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select your status</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                        <option value="widowed">Widowed</option>
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Gender</label>
                    <select
                        name="gender"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select your gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="lg:col-span-2">
                    <label className="block text-sm font-medium text-gray-700">ID Type</label>
                    <div className="mt-1 flex flex-wrap gap-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="idType"
                                value="passport"
                                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                checked={formData.idType === 'passport'}
                                onChange={handleChange}
                            />
                            <span className="ml-2">Passport</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="idType"
                                value="national_id"
                                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                checked={formData.idType === 'national_id'}
                                onChange={handleChange}
                            />
                            <span className="ml-2">National ID</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="idType"
                                value="drivers_license"
                                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                checked={formData.idType === 'drivers_license'}
                                onChange={handleChange}
                            />
                            <span className="ml-2">Driver's License</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="idType"
                                value="others"
                                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                checked={formData.idType === 'others'}
                                onChange={handleChange}
                            />
                            <span className="ml-2">Others</span>
                        </label>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">ID Number</label>
                    <input
                        type="text"
                        name="idNumber"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.idNumber}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Nationality</label>
                    <Select
                        options={options}
                        value={formData.nationality}
                        onChange={handleNationalityChange}
                        className="mt-1"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                    <input
                        type="date"
                        name="dateOfBirth"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Next of Kin</label>
                    <input
                        type="text"
                        name="nextOfKin"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.nextOfKin}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Relationship with Next of Kin</label>
                    <input
                        type="text"
                        name="relationship"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.relationship}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Next of Kin Phone Number</label>
                    <input
                        type="tel"
                        name="nextOfKinPhoneNumber"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.nextOfKinPhoneNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="lg:col-span-2">
                    <label className="block text-sm font-medium text-gray-700">Mother's Maiden Name</label>
                    <input
                        type="text"
                        name="motherMaidenName"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={formData.motherMaidenName}
                        onChange={handleChange}
                    />
                </div>
                <div className="lg:col-span-2">
                    <label className="block text-sm font-medium text-gray-700">Upload CV (In PDF Format)</label>
                    <input
                        type="file"
                        name="resume"
                        accept="application/pdf"
                        className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        onChange={handleFileChange}
                        required
                    />
                </div>
                <div className="lg:col-span-2">
                    <label className="block text-sm font-medium text-gray-700">Upload Passport Photograph (In picture format)</label>
                    <input
                        type="file"
                        name="passport"
                        accept="image/*"
                        className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        onChange={handleFileChange}
                        required
                    />
                </div>

                <div className="lg:col-span-2 w-full flex justify-center items-center">
                    <button
                        type="submit"
                        className="w-fit flex items-center justify-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={loading}
                    >
                        {loading ? <div className='mx-12'><GradientCircularProgress /></div> : <div className="flex items-center justify-center"> Submit Application <FaArrowRight className="ml-2" /></div>}
                    </button>
                </div>
            </form>

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded shadow-lg max-w-md mx-auto">
                        <h2 className="text-xl font-bold mb-4">Application Submitted</h2>
                        <p className="mb-4">Your application has been sent successfully and is currently being reviewed. A reply will be sent to your email once the review is completed.</p>
                        <button onClick={handleClosePopup} className="bg-blue-500 text-white py-2 px-4 rounded">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JobApplicationPage;
