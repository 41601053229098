import React from "react";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const HeroSection = () => {
    return (
        <div className=" w-full flex justify-between flex-col lg:flex-row mt-9">
            {/* Hero image */}
            <div className="w-full lg:w-[50%] xl:w-[45%] grid items-center  ">
                <img src="office.avif" alt="hero image" className="rounded-tl-[5vh] rounded-br-[5vh]" />
            </div>

            {/* Hero text content */}
            <div className="w-full lg:w-[50%] h-full ">
                <h1 className="text-2xl lg:text-5xl px-5 xl:px-2 md:text-6xl 2xl:text-7xl font-black text-[#2836b4] mt-10">
                    Empowering Global Solutions
                </h1>
                <p className="text-[#2341a1] text-lg 2xl:text-2xl px-5 xl:px-2 opacity-90  my-10">
                    Join our innovative team excelling in port operations, technical support, logistic services, software development, business consultation, and more. We offer dynamic energy, top-tier salaries, and excellent benefits. Our commitment to competitive compensation ensures you feel valued and motivated. Be part of our journey and enjoy a rewarding career!
                </p>
                <Link
                    to="/jobs"
                    className="mx-2 md:mx-3 lg:mx-5 xl:mx-2 px-10 md:px-12 lg:px-16 xl:px-12 2xl:px-16 py-2 md:py-3 lg:py-2 rounded-full text-base md:text-lg lg:text-lg xl:text-lg 2xl:text-lg font-medium text-white bg-[#3c4dec] hover:bg-[#282f9b] border-1 border-[#ffffff]"
                >
                    Join Us!
                </Link>

            </div>
        </div>
    );
};

export default HeroSection;