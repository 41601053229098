import React, { useEffect } from 'react';
import { Collapse } from 'antd';

const Faq = () => {
    const items = [
        {
            key: '1',
            label: 'What services does Axis Global Enterprise offer?',
            children: <p>{"Axis Global Enterprise offers a comprehensive range of services including port operations, freight forwarding, logistics management, technical support, software development and business consultation. Our services are designed to ensure efficient and reliable supply chain operations, tailored to meet the specific needs of our clients."}</p>,
        },
        {
            key: '15',
            label: 'How does Axis Global Enterprise support work-life balance?',
            children: <p>{"We understand the importance of work-life balance and strive to create a flexible and accommodating work environment. We offer various programs and policies to support our employees' well-being, including flexible work schedules, shifts and wellness initiatives."}</p>,
        },
        {
            key: '10',
            label: 'How do I get started with Axis Global Enterprise?',
            children: <p>{"To get started, you can contact us through our website or reach out to our customer service team. We will discuss your specific needs and provide tailored solutions to meet your logistics, technical support, software development or business consultation requirements."}</p>,
        },
        {
            key: '13',
            label: 'What kind of training and development opportunities do you offer?',
            children: <p>{"We offer a range of training and development programs to help our employees grow professionally. This includes on-the-job training, workshops, and access to online courses. We are committed to supporting continuous learning and career advancement. For more information, check the jobs section on the company's website."}</p>,
        },
        {
            key: '2',
            label: 'Where are your operations based?',
            children: <p>{"Our operations are based in key international ports around the world, with our headquarters located in Lome, Togo. We have a global network that allows us to provide seamless logistics solutions across various regions."}</p>,
        },
        {
            key: '5',
            label: 'Can you provide technical support for our existing systems?',
            children: <p>{"Yes, we offer 24/7 technical support services, addressing all technical inquiries promptly to minimize downtime and maintain productivity. Our team of experts specializes in troubleshooting hardware and software problems, offering both remote and on-site assistance."}</p>,
        },
        {
            key: '11',
            label: 'What qualifications do I need to apply for a job at Axis Global Enterprise?',
            children: <p>{"The qualifications vary depending on the position. Generally, we require relevant educational qualifications and experience in the field. Specific requirements are detailed in each job listing on the job page of our website."}</p>,
        },
        {
            key: '9',
            label: 'How can I track my shipment?',
            children: <p>{"We provide real-time tracking of shipments through our advanced logistics management system. Clients can monitor their cargo and receive updates throughout the shipping process, ensuring transparency and timely information."}</p>,
        },
        {
            key: '4',
            label: 'What makes Axis Global Enterprise different from other logistics companies?',
            children: <p>{"Our commitment to innovation, customer satisfaction, and sustainability sets us apart. We leverage cutting-edge technology, including IoT and advanced port management systems, to enhance operational transparency and efficiency. Additionally, we adopt eco-friendly practices and adhere to international maritime regulations to minimize our environmental impact."}</p>,
        },
        {
            key: '8',
            label: 'What steps do you take to ensure environmental sustainability?',
            children: <p>{"We adhere to international maritime regulations and adopt eco-friendly practices to minimize our environmental impact. This includes utilizing energy-efficient equipment, reducing waste, and implementing sustainable logistics practices."}</p>,
        },
        {
            key: '6',
            label: 'How do you handle customs clearance and documentation?',
            children: <p>{"Our logistics management services cover all aspects of customs clearance and documentation. We ensure compliance with all relevant regulations and work closely with customs authorities to facilitate smooth and timely clearance of goods."}</p>,
        },
        {
            key: '7',
            label: 'Do you offer business consultation services for startups?',
            children: <p>{"Yes, we provide business consultation services for companies of all sizes, including startups. Our experienced consultants offer insights and solutions tailored to meet the unique needs of each client, helping them navigate complex business challenges and achieve sustainable growth."}</p>,
        },
        {
            key: '14',
            label: 'What benefits do you offer to employees?',
            children: <p>{"As part of our commitment to excellence, we offer our employees a comprehensive benefits package, including accommodation, meals, security, and an attractive salary. We also provide a collaborative and supportive work environment."}</p>,
        },
        {
            key: '3',
            label: 'How do you ensure the security and integrity of cargo?',
            children: <p>{"We utilize advanced equipment and technology to handle cargo with precision, ensuring its safe transfer and minimizing delays. Our professional mooring services guarantee secure anchorage for vessels, and our logistics management services ensure compliance with all relevant regulations."}</p>,
        },
        {
            key: '16',
            label: 'What is the company culture like at Axis Global Enterprise?',
            children: <p>{"Our company culture is built on values of integrity, innovation, and collaboration. We foster an inclusive environment where employees are encouraged to share ideas, take initiative, and contribute to our collective success. We believe in recognizing and rewarding hard work and dedication."}</p>,
        },
        {
            key: '18',
            label: 'What is the onboarding process for new employees?',
            children: <p>{"Our onboarding process is designed to help new employees integrate smoothly into the company. It includes orientation sessions, training programs, and mentorship to ensure that new hires feel welcomed and prepared to succeed in their roles."}</p>,
        },
        {
            key: '12',
            label: 'What is the recruitment process like?',
            children: <p>{"Our recruitment process typically includes submitting an online application and participating in one or more interviews. We aim to make the process as smooth and transparent as possible."}</p>,
        },
        {
            key: '17',
            label: 'How do I apply for a job at Axis Global Enterprise?',
            children: <p>{"You can apply for a job through our website. Navigate to the jobs section, browse available positions, and submit your application online. We look forward to reviewing your application."}</p>,
        },
    ];


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold mb-6">Frequently Asked Questions</h2>
            <Collapse items={items} />
        </div>
    );
}

export default Faq;
