import React, { useEffect, useState } from 'react';
import { Button, List, Collapse, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import activeBaseUrl from '../../utils/urls';

const { Panel } = Collapse;

const Surveys = () => {
    const [cvModalVisible, setCvModalVisible] = useState(false);
    const [selectedCv, setSelectedCv] = useState(null);
    const [passportModalVisible, setPassportModalVisible] = useState(false);
    const [selectedPassport, setSelectedPassport] = useState(null);

    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchApplications = async () => {
        try {
            const response = await axios.get(`${activeBaseUrl}/applications`);
            setApplications(response.data.data);
            console.log(response.data.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApplications();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const employedCandidates = applications.filter(applicant => applicant.offeredEmployment);
    const surveyedCandidates = employedCandidates.filter(applicant => applicant.takenSurvey);

    const handleViewCV = (cv) => {
        setSelectedCv(cv);
        setCvModalVisible(true);
    };

    const handleCloseCVModal = () => {
        setSelectedCv(null);
        setCvModalVisible(false);
    };

    const handleViewPassport = (passport) => {
        setSelectedPassport(passport);
        setPassportModalVisible(true);
    };

    const handleClosePassportModal = () => {
        setSelectedPassport(null);
        setPassportModalVisible(false);
    };

    const renderItem = (item) => (
        <List.Item key={item._id}>
            <Collapse defaultActiveKey={['0']}>
                <Panel
                    header={
                        <div className="flex justify-between items-center">
                            <span className="font-bold">{`${item.firstname} ${item.surname}`}</span>
                            <div className="flex items-center">
                                <span className="text-blue-800 mr-4">{moment(item.createdAt).format('YYYY-MM-DD')}</span>
                                <span className="bg-blue-200 text-blue-800 px-4 py-1 rounded-lg">{item.role}</span>
                            </div>
                        </div>
                    }
                    key="1"
                >
                    {/* Display all fields within the collapsed card */}
                    <p>First Name: {item.firstname}</p>
                    <p>Middle Name: {item.middleName}</p>
                    <p>Surname: {item.surname}</p>
                    <p>Marital Status: {item.maritalStatus}</p>
                    <p>Gender: {item.gender}</p>
                    <p>Phone Number: {item.phoneNumber}</p>
                    <p>Email: {item.email}</p>
                    <p>ID Type: {item._idType}</p>
                    <p>ID Number: {item._idNumber}</p>
                    <p>Nationality: {item.nationality.label}</p>
                    <p>Date of Birth: {moment(item.dateOfBirth).format('YYYY-MM-DD')}</p>
                    <p>Next of Kin: {item.nextOfKin}</p>
                    <p>Relationship: {item.relationship}</p>
                    <p>Next of Kin Phone Number: {item.nextOfKinPhoneNumber}</p>
                    <p>Mother's Maiden Name: {item.motherMaidenName}</p>
                    <p>Role: {item.role}</p>
                    {/* CV and Passport Photograph fields */}
                    <p>CV: {item.resume ? <Button onClick={() => handleViewCV(item.resume)}>View CV</Button> : "Not Available"}</p>
                    <p>Passport Photograph: {item.passport ? <Button onClick={() => handleViewPassport(item.passport)}>View Passport</Button> : "Not Available"}</p>

                    <p className='font-bold text-green-500 mt-4'>Candidate Offered Employment and recieved survey email</p>
                </Panel>
            </Collapse>
        </List.Item>
    )

    return (
        <div>
            <h2 className="text-lg font-semibold mb-2">Surveys: {surveyedCandidates.length}</h2>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={applications.filter(applicant => applicant.takenSurvey)}
                renderItem={renderItem}
            />
            <Modal
                title="CV Preview"
                visible={cvModalVisible}
                onCancel={handleCloseCVModal}
                footer={[
                    <Button key="close" onClick={handleCloseCVModal}>
                        Close
                    </Button>
                ]}
            >
                {selectedCv && <iframe src={selectedCv} style={{ width: '100%', height: '500px' }} />}
            </Modal>
            <Modal
                title="Passport Photograph Preview"
                visible={passportModalVisible}
                onCancel={handleClosePassportModal}
                footer={[
                    <Button key="close" onClick={handleClosePassportModal}>
                        Close
                    </Button>
                ]}
            >
                {selectedPassport && <img src={selectedPassport} alt="Passport Photograph" style={{ maxWidth: '100%', maxHeight: '80vh' }} />}
            </Modal>
        </div>
    );
};

export default Surveys;
