import React, { useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { MdArrowForward } from 'react-icons/md';
import { slugify } from '../utils/slugs';
import HeroSection from '../components/hero';

const { Title } = Typography;

const Home = () => {
  const services = [

    {
      title: "Technical Support",
      description: "Comprehensive range of technical assistance services offered, ensuring efficient resolution of technical issues and optimal system functionality.",
      imgSrc: "technical.jpeg",
    },
    {
      title: "Software Development",
      description: "Our software development services encompass a wide array of expertise to cater to your project needs. We specialize in delivering high-quality, custom software solutions tailored to your specific requirements, ensuring optimal performance and scalability.",
      imgSrc: "software.jpg",
    },
    {
      title: "Port Operation",
      description: "Efficient management of cargo handling, vessel berthing, and logistics within the port facility, ensuring smooth flow of goods and materials.",
      imgSrc: "port1.jpg",
    },
    {
      title: "Business Consultation",
      description: "Expert guidance and support in diverse consulting services, providing insights and solutions tailored to client needs and industry requirements.",
      imgSrc: "business-consultants.jpg",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container lg:mx-auto px-4 md:px-8 lg:px-0">

      <HeroSection />

      <Row gutter={[16, 16]} className='mt-10 mb-4'>
        <Col span={24}>
          <Title level={2}>Our Services</Title>
        </Col>
      </Row>

      <ServicesSection services={services} />

      <div className="mt-10 flex justify-center">
        <Link to="/services" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-full text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Learn More
        </Link>
      </div>

      <Row gutter={[16, 16]} style={{ marginTop: '32px', marginBottom: '30px' }} justify="center" className=' text-center pt-10'>
        <Col span={18}>
          <Title level={2} style={{ fontWeight: 'bold' }} className='text-3xl'>Why Choose Us?</Title>
          <p className='font-normal text-lg tracking-wider'>
            Our seasoned expertise, backed by innovative technology, ensures tailored solutions that exceed expectations. With a dedicated focus on customer service, we deliver excellence, reliability, and trust. Experience the difference with our industry-leading team, committed to your success at every turn.
          </p>
        </Col>
      </Row>

      <NewsSection />

      <CareerSection />

    </div>
  );
}

const NewsSection = () => {
  const blogs = [
    {
      date: 'May 31, 2024',
      category: 'Careers',
      title: 'Join Our Team: We Are Hiring!',
      description: 'In a bid for global talent, our expanding company is proud to announce enticing career prospects for skilled individuals. Seize the opportunity to embark on a journey to success with us and be an integral part of our innovative and dynamic team. As part of our commitment to excellence, we offer international applicants a comprehensive benefits package, including accommodation, meals, security, and an attractive salary. Join us and step into the next chapter of your career journey today!',
      author: 'Human Resources',
      authorTitle: 'HR Department',
      imageUrl: 'hiring.avif',
    },
    {
      date: 'May 31, 2024',
      category: 'Partnership',
      title: 'Seeking Strategic Partnerships',
      description: 'We are open to forming strategic partnerships with like-minded companies. Join us in our mission to innovate and excel. Let’s collaborate and achieve greater success together.',
      author: 'Corporate Relations',
      authorTitle: 'Business Development Team',
      imageUrl: 'partnership.avif',
    },
  ];

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold text-gray-900">News and Updates</h2>
      <p className="mt-2 text-lg text-gray-600">Follow the latest happenings on our company.</p>

      <div className="mt-10 grid gap-10 lg:grid-cols-2">
        {blogs.map((blog, index) => (
          <div key={index} className="flex flex-col overflow-hidden rounded-xl shadow-lg">
            <img className="h-48 w-full object-cover" src={blog.imageUrl} alt={blog.title} />
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-indigo-600">
                  {blog.category}
                </p>
                <div className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">{blog.title}</p>
                  <p className="mt-3 text-base text-gray-600">{blog.description}</p>
                </div>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <div>
                    <span className="sr-only">{blog.author}</span>
                    <img className="h-10 w-10" src="logo512.png" alt={blog.author} />
                  </div>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900 hover:underline">
                    {blog.author}
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={blog.date}>{blog.date}</time>
                    <span aria-hidden="true">&middot;</span>
                    <span>{blog.authorTitle}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CareerSection = () => {
  const jobOpenings = [
    {
      "title": "Accountant",
      "location": "Lomé, Togo",
      "description": "Manage financial records, prepare financial statements, and ensure compliance with regulations.",
      "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in Accounting, Economics, Finance, or related field required. CPA or similar certification preferred. Strong attention to detail, analytical skills, and knowledge of accounting principles is essential.",
      "benefits": "Competitive salary, health insurance, and opportunities for professional development."
    },
    {
      "title": "Technical Support Engineer",
      "location": "Lomé, Togo",
      "description": "Provide technical support to clients, troubleshoot issues, and ensure system stability.",
      "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in any Engineering discipline, computer science, information technology, or related field preferred. Previous experience in technical support or IT helpdesk roles is advantageous. Strong problem-solving, communication, and customer service skills required. Knowledge of relevant software and systems is beneficial.",
      "benefits": "Competitive salary, opportunities for skill development and career advancement, health insurance, and a supportive work environment."
    },
    {
      "title": "Business Consultant",
      "location": "Lomé, Togo",
      "description": "Advise clients on business strategies, market analysis, and operational improvements.",
      "qualifications": "Minimum of OND (Ordinary National Diploma) or equivalent in business administration, management, finance, or related field preferred. Previous experience in consulting, business analysis, or relevant industries is advantageous. Strong analytical, problem-solving, and communication skills required. Knowledge of market trends, strategic planning, and industry best practices is beneficial.",
      "benefits": "Competitive salary, opportunities for professional development, health insurance, and a stimulating work environment."
    },
    {
      "title": "French Tutor",
      "location": "Lomé, Togo",
      "description": "Provide French language instruction to students, customize lessons, and assess proficiency.",
      "qualifications": "Native or near-native proficiency in French required. Previous experience in teaching or tutoring preferred. Strong communication, interpersonal, and instructional skills necessary. Certification in teaching French as a second language is advantageous.",
      "benefits": "Competitive salary, opportunities for professional development, and a rewarding work environment."
    }
  ];

  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold text-gray-900">Career Opportunities</h2>
      <p className="mt-2 text-lg text-gray-600">Join our team and become a part of a dynamic and innovative work environment. We believe in rewarding hard work and dedication with competitive salaries and comprehensive benefits. Our company values each employee's contribution and offers ample opportunities for professional growth and advancement. We are committed to ensuring that our employees are well-compensated and appreciated for their efforts, making this an excellent place to build a fulfilling career.</p>

      <div className="mt-10 grid gap-10 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {jobOpenings.map((job, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-900">{job.title}</h3>
            <p className="mt-2 text-sm text-gray-500">{job.location}</p>
            <p className="mt-4 text-base text-gray-600">{job.description}</p>
            {job.qualifications && (
              <div className="mt-4">Requirements
                <p className="mt-2 text-sm text-gray-600">{job.qualifications}</p>
              </div>
            )}
            {job.benefits && (
              <div className="mt-4">
                <h4 className="text-sm font-semibold text-gray-700">Benefits</h4>
                <p className="mt-2 text-sm text-gray-600">{job.benefits}</p>
              </div>
            )}
            <Link to={`/apply/${slugify(job.title)}`}>
              <button
                className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
                <MdArrowForward className="ml-2" />
              </button>
            </Link>
          </div>
        ))}
      </div>

      <div className="mt-10 flex justify-center">
        <Link to="/jobs" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          View More
        </Link>
      </div>
    </div>
  );
};

const ServicesSection = ({ services }) => (
  <Row gutter={[16, 16]} justify="center">
    {services.map((service, index) => (
      <Col key={index} xs={24} sm={12} md={8} lg={6}>
        <Card
          cover={<img alt={service.title} src={service.imgSrc} />}
        >
          <Card.Meta title={service.title} description={service.description} />
        </Card>
      </Col>
    ))}
  </Row>
);

export default Home;
