import React from 'react';
import AppRouter from './Router';

const App = () => {
  return (
    <AppRouter />
  );
}

export default App;
